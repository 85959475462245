<template>
  <div>
    <div v-if="questionInfo.type!=1" class="brief">
      <template  v-if="questionInfo.type==3">
        <div>参考答案：</div>
        <div style="flex: 1">
          <p v-for="(item,index) in (questionInfo.children || [])" :key="index">{{index+1}} 、 {{ (item.answer||[]).map(d=>{return d.answer === undefined ? d : d.answer}).join('、')}}</p>
        </div>
      </template>
      <template  v-else>
        <div>参考答案：</div>
        <div style="flex: 1">
          <p>{{ (questionInfo.answer||[]).map(d=>{return d.answer === undefined ? d : d.answer}).join('、')}}</p>
        </div>
      </template>
    </div>
    <el-divider v-if="questionInfo.type!=1"></el-divider>
    <div class="brief">
      <div>答案解析：</div>
      <div style="flex: 1">
        <span v-code-html="questionInfo.analysis" v-if="questionInfo.analysis"></span>
        <span v-else style="color:#979797">暂无解析</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questionInfo:{
      type:Object,
      default: () => { return {}; },
    },
  },
}
</script>

<style scoped>

.brief {
  font-size: 14px;
  line-height: 26px;
  display: flex
}
</style>
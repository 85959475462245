<template>
  <div>
    <component :is="title?'el-card':'div'">
      <div slot="header" class="clearfix" style="display: flex;" v-if="title">
        <el-image :src="headImg" style="width: 24px; height: 24px"></el-image>
        <span style="color: #00957e">{{ title }}</span>
      </div>
      <form-create ref="form" v-model="formData"
                   :inline="false"
                   label-width="110px"
                   :label-position="'right'"
                   :components="components"
                   @update="handleUpdate"
                   style="min-height:400px;"
      >
      </form-create>
      <slot name="footer">
        <el-button-group style="padding-top: 15px;display: flex;justify-content: flex-end;">
          <el-button type="primary" :disabled="loading"  @click="submitForm()">下一步</el-button>
        </el-button-group>
      </slot>
    </component>
  </div>
</template>

<script>
import DiyFormItem from "@/components/com/el/diyFormItem";
export default {
  name: "questionSave",
  components: {DiyFormItem},
  props:['question_id','title'],
  data(){
    var _this = this;
    return {
      formData:{},
      courseInfo:{},
      loading:false,
      components:[
        {name:'el-row' ,components:[
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'user_id',required:true,disabled:true,label:'创建人', type:'select'
                  // ,data:{ url:'/xapi/user/teachList', labelOptions:{value:'id',label:'showname'} }
                  ,data:{ url:'/xapi/user.user/teachList', labelOptions:{value:'id',label:'showname'} }
                }},
                {name:'form-item',props:{prop:'is_open',required:true,label:'公开程度',type:'radio',data:[{label:'公开',value:1},{label:'私人',value:2},{label:'团队',value:0},]}},
              ]},
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'type',required:true,label:'题目类型', type:'select',data:[{label:'编程',value:1},{label:'非编程(普通)',value:2},{label:'一题多问',value:3},]},callback(c){
                    c.props.disabled = !!_this.formData.id
                  }},
                {name:'form-item',props:{prop:'is_select',label:'题型', type:'select',data:[{label:'填空题',value:0},{label:'单选题',value:1},{label:'判断题',value:2},{label:'多选题',value:3},]}
                ,callback(c){
                    c.props.disabled = !!_this.formData.id
                    c.props.required=_this.formData.type == 2?true:false
                    c.props.hidden = _this.formData.type != 2
                }},
              ]}
          ]
        },
        {name:'form-item', condition:'{{is_open}} == 0',props:{prop:'group_id',label:'选择团队',required:true, type:'select',multiple:true,data:{ url:'/xapi/user.group/list',params:{type:1}, labelOptions:{value:'id',label:'name'} }}},
        // {name:'form-item',props:{prop:'title',label:'题目名称',required:true ,type:'input',editorConfig:'title' },style:{position:'relative',zIndex:0}},
        {name:'form-item',props:{prop:'title',label:'题目名称',required:true ,type:'editor',editorConfig:'title' },style:{position:'relative',zIndex:0}},
        {name:'form-item',props:{prop:'description',label:'题目描述',required:true , type:'editor'},style:{position:'relative',zIndex:0}},
        {name:'form-item',props:{prop:'text_input',label:'输入描述',required:true , type:'editor'},style:{position:'relative',zIndex:0}, condition:'{{type}} == 1'},
        {name:'form-item',props:{prop:'text_out',label:'输出描述',required:true , type:'editor'}, condition:'{{type}} == 1'},
        {name:'divider-box' , condition:'{{type}} == 1',
          props:{borderColor:'#c3c3c3',contentPosition:'left',position:80 , title:'测试用例'},
          components:[
            {name:'form-item',props:{prop:'use_cases',itemType:2,columns:[
                  {prop:'input',label:'样例输入',options:{labelWidth:'80px'}, type:'textarea',rows:4,resize:'none'},
                  {prop:'result',label:'样例输出',options:{labelWidth:'80px'}, type:'textarea',rows:4,resize:'none'},
                  {prop:'is_show',width:'80px',align:'center',itemType:0, options:{type:'checkbox',size:'mini',data:[{label:'显示',value:1}]}},
                  {prop:'explain',label:'解释',type:'expand',options:{labelWidth:'80px',type:'editor',editorConfig:'base' }},
                ],
              }
            },
          ]
        },
        {name:'divider-box' , condition:'{{type}} == 2 && {{is_select}} == 0',
          props:{borderColor:'#c3c3c3',contentPosition:'left',position:80 , title:'答案'},
          components:{ name:'form-item' , props:{prop:'answers',label:'答案'},
            components:[
              {name:'form-item',props:{prop:'answers',itemType:2,showHeader:false,columns:[
                    {prop:'answer',options:{labelWidth:'0px'}},
                    {prop:'grade',label:'分数',options:{labelWidth:'60px',type:'number'},width:'200px'},
                    {prop:'ttype',label:'是否去空', type:'switch',options:{labelWidth:'80px'},width:'150px',activeValue:1,inactiveValue:0},
                  ],
                }
              },
            ]
          }
        },
        {name:'divider-box' , condition:'{{type}} == 2 && {{is_select}} == 1',
          props:{borderColor:'#c3c3c3',contentPosition:'left',position:80 , title:'答案'},
          components:[
            {name:'form-item',props:{prop:'options',itemType:2,min:4,max:10,columns:[
                  {prop:'id',hidden:true},
                  {prop:'code',hidden:true,callback:this.setCodes},
                  {prop:'is_right',type:'radio',width:'120px',columnKey:'code',className:'el-table__cell-full'},
                  {prop:'title',label:'答案',options:{labelWidth:'60px'},type:'editor',editorConfig:'base'},
                ],
              }
            },
          ]
        },
        {name:'divider-box' , condition:'{{type}} == 2 && {{is_select}} == 2',
          props:{borderColor:'#c3c3c3',contentPosition:'left',position:80 , title:'答案'},
          components:[
            {name:'form-item',props:{prop:'options',showAction:false,itemType:2,min:2,max:2,columns:[
                  {prop:'id',hidden:true},
                  {prop:'code',hidden:true,callback:this.setCodes},
                  {prop:'is_right',type:'radio',width:'120px',columnKey:'code',className:'el-table__cell-full'},
                  {prop:'title',label:'答案',options:{labelWidth:'60px'},defaultValue:(val,index)=>{return ["对","错"][index];}},
                ],
              }
            },
          ]
        },
        {name:'divider-box' , condition:'{{type}} == 2 && {{is_select}} == 3',
          props:{borderColor:'#c3c3c3',contentPosition:'left',position:80 , title:'答案'},
          components:[
            {name:'form-item',props:{prop:'options',itemType:2,min:4,max:10,columns:[
                  {prop:'id',hidden:true},
                  {prop:'code',hidden:true,callback:this.setCodes},
                  {prop:'is_right',type:'checkbox',width:'120px',columnKey:'code',className:'el-table__cell-full'},
                  {prop:'title',label:'答案',options:{labelWidth:'60px'},type:'editor',editorConfig:'base'},
                ],
              }
            },
          ]
        },
        {name:'divider-box' , condition:'{{type}} == 3',
          props:{borderColor:'#c3c3c3',contentPosition:'left',position:80 , title:'一题多问'},
          components:[
            {name:'form-item',props:{prop:'children',itemType:2,columns:[
                  {prop:'type',hidden:true},
                  {prop:'is_select',label:'题型', type:'select',data:[{label:'填空题',value:'0'},{label:'选择题',value:'1'},{label:'判断题',value:'2'},{label:'多选题',value:'3'},]},
                  
                 {prop:'grade',required:true,label:'子题分数', type:'number',step:0.01},
                  {type:'expand'
                    , components:[
                      { name:'form-item',props:{prop:'title',label:'子题目名称',required:true ,type:'editor',editorConfig:'title' }},
                      { name:'form-item' , props:{prop:'answers',label:'答案'}, condition:'{{$value.is_select}} == 0',
                        components:[
                          {name:'form-item',props:{prop:'answers',itemType:2,showHeader:false,columns:[
                                {prop:'answer',options:{labelWidth:'0px'}},
                                {prop:'grade',label:'分数',options:{labelWidth:'60px',type:'number'},width:'200px'},
                                {prop:'ttype',label:'是否去空', type:'switch',options:{labelWidth:'80px'},width:'150px',activeValue:1,inactiveValue:0},
                              ],
                            }
                          },
                        ]
                      },
                      { name:'form-item' , props:{prop:'options',label:'单选项'}, condition:'{{$value.is_select}} == 1',
                        components:[
                          {name:'form-item',props:{prop:'options',itemType:2,min:4,max:10,columns:[
                                {prop:'id',hidden:true},
                                {prop:'code',hidden:true,callback:this.setCodes},
                                {prop:'is_right',type:'radio',width:'120px',columnKey:'code',className:'el-table__cell-full'},
                                {prop:'title',label:'答案',options:{labelWidth:'60px'},type:'editor',editorConfig:'base'},
                              ],
                            }
                          },
                        ]
                      },
                      { name:'form-item' , props:{prop:'options',label:'判断选项'}, condition:'{{$value.is_select}} == 2',
                        components:[
                          {name:'form-item',props:{prop:'options',showAction:false,itemType:2,min:2,max:2,columns:[
                                {prop:'id',hidden:true},
                                {prop:'code',hidden:true,callback:this.setCodes},
                                {prop:'is_right',type:'radio',width:'120px',columnKey:'code',className:'el-table__cell-full'},
                                {prop:'title',label:'答案',options:{labelWidth:'60px'},defaultValue:(val,index)=>{return val || ["对","错"][index];}},
                              ],
                            }
                          },
                        ]
                      },
                      { name:'form-item' , props:{prop:'options',label:'多选项'}, condition:'{{$value.is_select}} == 3',
                        components:[
                          {name:'form-item',props:{prop:'options',itemType:2,min:4,max:10,columns:[
                                {prop:'id',hidden:true},
                                {prop:'code',hidden:true,callback:this.setCodes},
                                {prop:'is_right',type:'checkbox',width:'120px',columnKey:'code',className:'el-table__cell-full'},
                                {prop:'title',label:'答案',options:{labelWidth:'60px'},type:'editor',editorConfig:'base'},
                              ],
                            }
                          },
                        ]
                      },
                    ]},
                ],
              },
            },
          ],
        },
        {name:'form-item',props:{prop:'other_supply',label:'文本说明' , type:'editor'}, condition:'{{type}} == 1'},
        {name:'form-item',props:{prop:'analysis',label:'解析' , type:'editor'}},

        {name:'el-row' ,components:[
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'grade',required:true,label:'分数', type:'number',step:1},slots:{after:"分"},
                callback(c){
                    c.props.disabled = (_this.formData.type == 2 && _this.formData.is_select == 0) || _this.formData.type == 3
                }},
                {name:'form-item',props:{prop:'time_limit',required:true,label:'时间限制', type:'number',step:1},slots:{after:"秒"},
                callback(c){
                   c.props.hidden = _this.formData.type != 1
                  }},
                {name:'form-item',props:{prop:'use_cases_price',label:'测试用例', type:'number',step:0.01},slots:{after:"元"},callback(c){
                    c.props.disabled = _this.formData.type+'' !== '1';
                     c.props.hidden = _this.formData.type != 1
                  }},
                {name:'form-item',props:{prop:'from_id',label:'来源', type:'select',data: {url:'/xapi/question.question/getFromList', labelOptions:{value:'id',label:'title'}}}},
              ]},
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'time_str',label:'出题时间', type:'year'},slots:{after:"年"}},
                {name:'form-item',props:{prop:'size_limit',required:true,label:'内存限制',defaultValue:256, type:'number',step:0.01},slots:{after:"MB"},
                callback(c){
                   c.props.hidden = _this.formData.type != 1
                  }},
                {name:'form-item',props:{prop:'diff_id',label:'难度',required:true, type:'select',data: {url:'/xapi/question.question/getDiffList', labelOptions:{value:'id',label:'title'}}}},
                {name:'form-item',props:{prop:'province_id',label:'省份', type:'select' , placeholder:'请选择省份', data:{ name:'question', keys:'province_id',isAll:false }}},
              ]}
          ]
        },
        {name:'form-item',props:{prop:'know_ids',label:'知识点',required:true, type:'checkbox',data: {url:'/xapi/question.question/getKnowList', labelOptions:{value:'id',label:'title'}}}}, {name:'el-row' ,components:[
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'sort',label:'排序权重',type:'number',min:0}},
              ]},
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'recommend',label:'是否推荐', type:'radio',data:[{label:'不推荐',value:0},{label:'推荐',value:1}]}},
              ]}
          ]
        },
      ],
      isOnlyField:false,
      headImg:require('@/assets/images/problemList/标签@2x.png')
    }
  },
  watch:{
    userInfo:{
      immediate:true,
      deep:true,
      handler(v,o){
        if(!this.$tool.obj_equal(v,o)){
          this.formData.user_id = (v || {}).id
        }
      },
    }
  },
  created() {
    this.formLoading = true;
  },
  methods:{
    routeUpdate(){
      this.getDetail();
    },
    getDetail(){
      if(this.question_id && this.question_id !== '0'){
        this.loading = true;
        this.$http.post('/xapi/question.question/detail' ,{id:this.question_id,is_answer:true}).then(({data , isOnlyField})=>{
          data = data || {};
          if(data.children){
            for (let i = 0; i < data.children.length; i++) {
              if(data.children[i].is_select === 0){
                data.children[i].answers = data.children[i].answer || [];
              }
            }
          }else if(data.type === 2 && data.is_select === 0){
            data.answers = data.answer || [];
          }
          setTimeout(()=>{
            this.formData = data;
          this.formData.grade=Number(data.grade)
          this.formData.time_limit=Number(data.time_limit)
          this.formData.use_cases_price=Number(data.use_cases_price/100)
          console.log(this.formData,"this.formData");
          },200)
          this.isOnlyField = isOnlyField === true;
        }).finally(()=>{
          this.loading = false;
        });
      }else{
        let formData = {user_id:this.userInfo.id || 0,...this.$route.query,...this.$route.params};
        if(!formData.type  || ['1','2','3'].indexOf(formData.type+'') === -1){
          formData.type = 1;
        }
        this.formData = {...formData};
      }
    },
    setCodes(val,index){
      return ["A","B","C","D","E","F","G","H","I","J","K","L","M","N"][index];
    },
    handleUpdate({prop , value , prefix , vNode }){
      let type = parseInt(this.formData.type)
      let is_select = parseInt(this.formData.is_select || 0)
      let $index = null
      let grade = 0
      if(type === 2){
		if([1,2,3].indexOf(is_select) > -1){
          this.formData.answer = (this.formData.options || []).filter(d=>d.is_right).map(d=>d.code)
        }else if(is_select === 0){
          this.formData.answer = (this.formData.answers || []).map((d)=>{
			grade +=parseFloat(d.grade||0)
			return d;
		  })
		  this.formData.grade = grade;
		}
		if(is_select === 0){
          this.formData.answer = [...(this.formData.answers || [])]
        }else if([1,2,3].indexOf(is_select) > -1){
          this.formData.answer = (this.formData.options || []).filter(d=>d.is_right).map(d=>d.code)
        }
      }
	  if(type === 3){
      this.formData.children = (this.formData.children|| []).map(d=>{
          let is_select = parseInt(d.is_select || 0)
          d.type = 3;
          let _grade = 0;
          if([1,2,3].indexOf(is_select) > -1){
            _grade = parseFloat(d.grade || 0);
            d.answer = (d.options || []).filter(d=>d.is_right).map(d=>d.code)
          }else{
            d.answer = (d.answers || []).map((d)=>{
              _grade +=parseFloat(d.grade||0)
              return d;
            })
            d.grade = _grade;
          }
          grade += _grade
          return d;
        });
        this.formData.grade = grade;
        this.formData.answer = null
      }
    },
    submitForm(cb){
      if(this.loading)return ;
      this.loading = true;
      this.$refs.form.validate((_form,valid , errors)=>{
        if(valid){
          this.$http.post('/xapi/question.question/save' , {...this.$route.params ,..._form,answer:this.formData.answer}).then(({msg})=>{
            this.$handleMessage(msg , 'success',()=>{
              if(cb && typeof cb === "function"){
                cb();
              }else{
                this.$handleRoute('/teaching/question')
              }
            });
          }).finally(()=>{
            this.loading = false;
          });
        }else if(errors.length > 0){
          this.loading = false;
          this.$handleMessage(errors[0].message , 'error');
        }
      } , this.isOnlyField)
    },
  }
}
</script>

<style scoped>

</style>
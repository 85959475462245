<template>
  <div>
    <div class="description" v-code-html="questionInfo.description"></div>
    <div style="margin-top:20px;" v-if="questionInfo.type+'' === '1'">
      <div >
        <div class="use_case">输入格式：</div>
        <div style="white-space: pre-wrap;width: 100%;" v-code-html="questionInfo.text_input"></div>
      </div>
      <el-divider></el-divider>
      <div>
        <div class="use_case">输出格式：</div>
        <div style="white-space: pre-wrap;width: 100%;" v-code-html="questionInfo.text_out"></div>
      </div>
      <el-divider></el-divider>
      <div v-for="(item,index) in questionInfo.use_cases.filter(v=>v.is_show+''==='1')" :key="index" class="use_cases">
        <div style="white-space: pre-wrap;" class="use_case">样例{{index+1}}输入：</div>
        <div style="white-space: pre-wrap;">{{item.input}}</div>
        <el-divider></el-divider>
        <div style="white-space: pre-wrap;" class="use_case">样例{{index+1}}输出：</div>
        <div style="white-space: pre-wrap;">{{item.result}}</div>
        <el-divider></el-divider>
        <template v-if="item.explain">
          <div style="white-space: pre-wrap;" class="use_case">样例{{index+1}}解释：</div>
          <div style="white-space: pre-wrap;width: 100%;margin-left: 20px;" v-code-html="item.explain"></div>
          <el-divider></el-divider>
        </template>
      </div>
    </div>
    <template v-if="questionInfo.other_supply && questionInfo.type+'' === '1'">
      <div style="white-space: pre-wrap;" class="use_case">说明提示：</div>
      <div style="white-space: pre-wrap;width: 100%;" v-code-html="questionInfo.other_supply"></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    questionInfo:{
      type:Object,
      default: () => { return {}; },
    },
  },
  mounted() {
    setTimeout(()=>{
      var _this = this,tabHeaderWidth = this.$el.offsetWidth ? this.$el.offsetWidth: null;
      tabHeaderWidth && this.$jquery(this.$el).find('pre').each(function(){
        _this.$jquery(this).css('maxWidth',(tabHeaderWidth - (this.offsetWidth - _this.$jquery(this).find('code').outerWidth(true)))+'px' );
      })
    } , 500)
  }
}
</script>

<style scoped>
::v-deep *{
  max-width: 100%;
}
</style>
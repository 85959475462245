<template>
  <div class="course_list">
    <el-image :src="v.cover" v-for="(v,i) in course_list.slice(0,2)" :key="i" class="img" @click="goCourse(v)"></el-image>
  </div>

</template>

<script>
import {
  courseHot
} from "@/api/course.js"
export default {
  data () {
    return {
      course_list: []
    }
  },
  created () {
    courseHot({}).then(res => {
      // console.log(res,'courseHot');
      this.course_list = res.data.slice(0, 2)
    })
  },
  methods: {
    goCourse ({ id }) {
      this.$handleRoute({ course_id: id },'courseDetail')
    }
  },
};
</script>

<style lang="less" scoped>
.course_list {
  .img:nth-child(1) {
    margin-bottom: 10px;
  }
  .img {
    width: 100%;
    height: 200px;
    cursor: pointer;
  }
}
</style>